





























































































import { popoStore } from '@/stores/popo-store'
import { when } from 'mobx'
import { Observer } from 'mobx-vue'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'decorate-tab': () => import('@/modules/popo/components/decorates/decorate-tab.vue'),
    'closet-tab': () => import('@/modules/popo/components/avatar-closets/closet-tab.vue'),
  },
})
export default class extends Vue {
  popoStore = popoStore
  decorators = [] as any
  tabs = ['Decorate', 'Closet']
  tab = null
  saveAvatarLoading = false
  async saveAvatar() {
    this.saveAvatarLoading = true
    if (!this.tab) await popoStore.generatePopoAvatar(popoStore.decoratedNfts)
    else await popoStore.updateAvatar()
    this.saveAvatarLoading = false
  }
}
